//get env variables
const BACKEND_AUTH_ENDPOINT = process.env.REACT_APP_BACKEND_AUTH_ENDPOINT;
const BACKEND_API_ENDPOINT = process.env.REACT_APP_BACKEND_API_ENDPOINT;
const BACKEND_PUBLIC_FOLDER = process.env.REACT_APP_PUBLIC_FOLDER;
const BACKEND_S3_ENDPOINT = process.env.REACT_APP_S3_ENDPOINT;
console.log('BACKEND_API_ENDPOINT1', BACKEND_API_ENDPOINT);
console.log('BACKEND_AUTH_ENDPOINT1', BACKEND_AUTH_ENDPOINT);
console.log('BACKEND_PUBLIC_FOLDER', BACKEND_PUBLIC_FOLDER);
console.log('BACKEND_S3_ENDPOINT', BACKEND_S3_ENDPOINT);

export {
  BACKEND_API_ENDPOINT,
  BACKEND_AUTH_ENDPOINT,
  BACKEND_PUBLIC_FOLDER,
  BACKEND_S3_ENDPOINT,
};
