import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {Checkbox, Form, Input, Button, message, Space} from 'antd';
import {useIntl} from 'react-intl';
import IntlMessages from '@crema/helpers/IntlMessages';
import {
  BACKEND_AUTH_ENDPOINT,
  BACKEND_API_ENDPOINT,
} from '../../../app/constants';

import {
  SignInButton,
  StyledRememberMe,
  StyledSign,
  StyledSignContent,
  StyledSignForm,
  StyledSignLink,
} from './index.styled';
import './index.scss';

const SignInCustom = () => {
  const [loadings, setLoadings] = useState([false, false, false]);
  const [countdown, setCountdown] = useState(0);
  const [rememberMe, setRememberMe] = useState(false);

  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;

      return newLoadings;
    });

    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;

        return newLoadings;
      });
    }, 6000);
  };

  const navigate = useNavigate();
  const {messages} = useIntl();

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const onGoToForgetPassword = () => {
    navigate('/forget-password', {tab: 'firebase'});
  };

  const onRememberMe = (e) => {
    setRememberMe(e.target.checked);
    if (e.target.checked) {
      localStorage.setItem('email', email);
      localStorage.setItem('password', password);
    } else {
      localStorage.removeItem('email');
      localStorage.removeItem('password');
    }
  };

  const [isOtpEnabled, setIsOtpEnabled] = useState(0);
  const [isOtpButtonEnabled, setIsOtpButtonEnabled] = useState(1);

  const [isLoginButtonEnabled, setIsLoginButtonEnabled] = useState(0);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('');

  useEffect(() => {
    const storedEmail = localStorage.getItem('email');
    const storedPassword = localStorage.getItem('password');
    if (storedEmail && storedPassword) {
      setEmail(storedEmail);
      setPassword(storedPassword);
      setRememberMe(true);
    }
  }, []);

  useEffect(() => {
    if (email != '' && password != '') {
      setIsLoginButtonEnabled(1);
    } else {
      setIsLoginButtonEnabled(0);
    }
  }, [email, password]);

  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    } else {
      setIsOtpButtonEnabled(1);
    }
    return () => clearTimeout(timer);
  }, [countdown]);

  const sendOTP = (e) => {
    e.preventDefault();
    if (email == '' || password == '') {
      messageAlert('error', 'Please enter email and password');
      return;
    }

    generateOTP(email, password);
  };

  const generateOTP = (email, password) => {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);

    enterLoading(0);
    fetch(`${BACKEND_AUTH_ENDPOINT}generate-otp`, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 1) {
          messageAlert('success', 'OTP sent to your mobile number');

          setIsOtpButtonEnabled(0);
          setIsOtpEnabled(1);
          setCountdown(60); // Start the countdown from 60 seconds
        } else {
          messageAlert('error', 'Invalid email or password');
          setIsOtpButtonEnabled(1);
          setIsOtpEnabled(0);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const signInUserSubmit = (values) => {
    if (email == '' || password == '') {
      messageAlert('error', 'Please enter email and password');
      return;
    }

    if (isOtpEnabled == 1 && otp == '') {
      messageAlert('error', 'Please enter OTP');
      return;
    }

    //get query string isotpenabled
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const disableOTP = urlParams.get('otp_disabled');

    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);
    formData.append('otp', otp);

    const loginUrl =
      disableOTP == 1
        ? `${BACKEND_AUTH_ENDPOINT}login?otp_disabled=1`
        : `${BACKEND_AUTH_ENDPOINT}login`;

    fetch(loginUrl, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 1) {
          messageAlert('success', 'Login successful');
          localStorage.setItem('token', data.authorisation.token);
          localStorage.setItem('user', JSON.stringify(data.user));

          if (data.user.user_role_id == 1 || data.user.user_role_id == 2) {
            window.location.href = '/admin/dashboard';
          } else if (
            data.user.user_role_id == 3 ||
            data.user.user_role_id == 4
          ) {
            window.location.href = '/dashboard';
          } else if (data.user.user_role_id == 5) {
            window.location.href = '/accountant/dashboard';
          } else if (data.user.user_role_id == 6) {
            window.location.href = '/visa-agent/dashboard';
          } else if (data.user.user_role_id == 7) {
            window.location.href = '/ticket-agent/dashboard';
          } else if (data.user.user_role_id == 8) {
            window.location.href = '/operation-head/dashboard';
          } else if (data.user.user_role_id == 9) {
            window.location.href = '/manager/dashboard';
          } else if (data.user.user_role_id == 10) {
            window.location.href = '/warehouse-manager/dashboard';
          } else {
            window.location.href = '/';
          }
        } else {
          messageAlert('error', 'Invalid email or password or OTP');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const messageAlert = (type, msg) => {
    message[type](msg);
  };

  return (
    <StyledSign>
      <StyledSignContent>
        <StyledSignForm
          name='basic'
          initialValues={{
            remember: true,
            email: '',
            password: '',
            otp: '',
          }}
          onFinish={signInUserSubmit}
          onFinishFailed={onFinishFailed}
          className='sign-form'
        >
          <Form.Item
            name='email'
            className='form-field'
            rules={[{required: true, message: 'Please input your Email!'}]}
          >
            <Input
              placeholder={messages['common.email']}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              value={email}
            />
          </Form.Item>

          <Form.Item
            name='password'
            className='form-field'
            rules={[{required: true, message: 'Please input your Password!'}]}
          >
            <Input.Password
              placeholder={messages['common.password']}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              value={password}
            />
          </Form.Item>

          <Form.Item
            className='form-field otp-field'
            rules={[{required: false, message: 'Please input your OTP'}]}
            style={{display: 'flex-inline'}}
          >
            <Input
              placeholder='OTP'
              title=''
              disabled={isOtpEnabled == 1 ? false : true}
              style={{width: '80%'}}
              onChange={(e) => {
                setOtp(e.target.value);
              }}
              value={otp}
            />

            <Button
              type='primary'
              loading={loadings[0]}
              onClick={sendOTP}
              disabled={isOtpButtonEnabled == 1 ? false : true}
            >
              {countdown > 0 ? `Resend OTP in ${countdown}s` : 'Send OTP'}
            </Button>
          </Form.Item>

          <StyledRememberMe>
            <Checkbox onChange={onRememberMe} checked={rememberMe}>
              <IntlMessages id='common.rememberMe' />
            </Checkbox>
            <StyledSignLink onClick={onGoToForgetPassword}>
              <IntlMessages id='common.forgetPassword' />
            </StyledSignLink>
          </StyledRememberMe>

          <div className='form-btn-field'>
            <SignInButton type='primary' htmlType='submit'>
              <IntlMessages id='common.login' />
            </SignInButton>
          </div>
        </StyledSignForm>
      </StyledSignContent>
    </StyledSign>
  );
};

export default SignInCustom;
